import { DomainException } from './exceptions';

export type SecurityChallengeScope = 'verify_email' | 'reset_password';
export type SecurityChallengeChannel = 'email';

export type SecurityChallengeErrorCode = 'invalid-scope' | 'invalid-channel' | 'not-found' | 'invalid-code';

export const SecurityChallengeErrorMessages: Record<SecurityChallengeErrorCode, string> = {
  'not-found': 'Challenge not found, or it has expired',
  'invalid-scope': 'Invalid challenge scope',
  'invalid-channel': 'Invalid challenge channel',
  'invalid-code': 'Invalid code',
};

export class SecurityChallengeException extends DomainException {
  constructor(
    public readonly code: SecurityChallengeErrorCode,
    originalError?: any,
  ) {
    super(SecurityChallengeErrorMessages[code], originalError);
  }
}

export class SecurityChallengeEntity {
  constructor(
    public readonly id: string,
    public readonly type: 'one-time-password',
    public readonly scope: SecurityChallengeScope,
    public readonly channel: SecurityChallengeChannel,
    public readonly channelIdentifier: string,
    public readonly expiresAt: Date,
    public readonly canResentAt: Date,
  ) {}
}
